<template>
  <a-table
    :pagination="false"
    :show-header="showHeader"
    :columns="columns"
    :data-source="data"
    bordered
  >
    <span slot="customTitle">
      {{ headerTitle }}
    </span>

    <template slot="name" slot-scope="text, obj">
      <p>{{ text }}</p>
      <p class="table-color">
        {{ obj.textSub || "" }}
      </p>
    </template>
    <template slot="one" slot-scope="text">
      <detail-table-column :text="text" />
    </template>
    <template slot="two" slot-scope="text">
      <detail-table-column :text="text" />
    </template>
    <template slot="three" slot-scope="text">
      <detail-table-column :text="text" />
    </template>
    <template slot="four" slot-scope="text">
      <detail-table-column :text="text" />
    </template>
  </a-table>
</template>

<script>
// 列宽
// const colWidth = 236
import detailTableColumn from './detailTableColumn.vue'
export default {
  components: {
    detailTableColumn
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    headerTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.table-color {
  color: #6e737c;
}

// 标题文字左
::v-deep .ant-table-thead > tr:first-child > th:last-child {
  text-align: left;
}
</style>
