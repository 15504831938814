<template>
  <div>
    <div>
      <el-tabs v-model="activeName" stretch class="tabs-class" @tab-click="handleClick">
        <el-tab-pane class="tabs-items" label="爱心版" name="0" />
        <el-tab-pane class="tabs-items" label="基础版" name="1" />
        <el-tab-pane class="tabs-items" label="专业版" name="2" />
        <el-tab-pane class="tabs-items" label="旗舰定制版" name="3" />
      </el-tabs>
    </div>
    <div class="card">
      <div class="card-content">
        <div
          class="contentImport"
          style="border-radius: 12px;"
          :style="showitem.color"
        >
          <div>
            <div class="card-title" style="padding-top: 15px;">
              {{ showitem.varName }}
            </div>
            <div class="card-title">
              {{ showitem.enName }}
            </div>
            <div
              v-show="showText"
              style="margin-top: 30px;height: 100px;text-align: center; display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 15px;"
            >
              <p>{{ showitem.contentone }}</p>
              <p>{{ showitem.contenttwo }}</p>
              <p>{{ showitem.contentthree }}</p>
              <p>{{ showitem.contentfour }}</p>
              <p>{{ showitem.contentfive }}</p>
              <p>{{ showitem.contentsix }}</p>
            </div>
            <div class="card-price-month" style="margin-top: 30px;font-size: 3rem">
              <!-- ¥400/月 -->
              {{ showitem.price == 0 ? "免费" : showitem.price }}
            </div>
            <div class="card-price-month" style="padding-bottom: 15px;font-size: 3rem">
              <!-- 月付 ¥480/月 -->
              {{ showitem.price2 == 0 ? "&nbsp;" : showitem.price2 }}
            </div>

            <el-button
              v-if="showPriceBtn"
              style="margin-bottom: 25px;"
              :class="comboId > showitem.comboId?'nobuybtn' : 'buybtn'"
              :disabled="comboId > showitem.comboId"
              @click="toPayPageI()"
            >
              {{ comboId > showitem.comboId? '你已购买更高版本' : showitem.butLabel }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- card -->
    <div class="table-col">
      <a-table
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.one"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          店铺管理
        </template>
      </a-table>
      <!-- 二 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.two"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          一件代发
        </template>
      </a-table>
      <!-- 3 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.three"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          广告投放
        </template>
      </a-table>

      <!-- 4 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.four"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          站内转化
        </template>
      </a-table>
      <!-- 5 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.five"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          特色功能
        </template>
      </a-table>
      <!-- 6 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.six"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          云手机
        </template>
      </a-table>
      <!-- 33 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.threethree"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
        <template slot="footer">
          贴心服务
        </template>
      </a-table>
      <!-- 7 -->
      <a-table
        style="width: 100%;"
        :pagination="false"
        :show-header="false"
        :columns="columns"
        :data-source="showDataList.seven"
        bordered
      >
        <template slot="name" slot-scope="text, obj">
          <p>{{ text }}</p>
          <p class="table-color">
            {{ obj.textSub || "" }}
          </p>
        </template>
        <template slot="one" slot-scope="text">
          <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
            {{ text }}
          </p>
          <div v-else class="table-no-data" />
        </template>
      </a-table>
      <!-- end -->
      <p class="tipText">
        *以上价格套餐内容自2021年8月23日起生效。
      </p>
    </div>
  </div>
</template>

<script>
import { setShopPackage } from '@/utils/cookie.js'
import { getPriceList, getUserInfo } from '@/api/register' //
export default {
  props: {
    showPriceBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comboId: -1,
      showitem: {},
      showDataList: [],
      activeName: '0',
      showText: true,
      listA: [
        {
          color: 'background-color: #EE9CA7;',
          varName: '爱心版',
          enName: 'Love version',
          contentone: 'Vllshop响应国家号召',
          contenttwo: '针对应届大学生、',
          contentthree: '退伍军人、残疾人',
          contentfour: '等特殊群体的',
          contentfive: '就业和创业',
          contentsix: '提供爱心版免费服务',
          price: '0',
          price2: '0',
          butLabel: '申请套餐',
          type: 'V1'
        },
        {
          color: 'background-color: #4B6CB7;',
          varName: '基础版',
          enName: 'Basic Edition',
          contentone: '绝佳的启动方式',
          contenttwo: '适合新手卖家快速上手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '年/4300人民币',
          price2: '月/430人民币',
          butLabel: '购买套餐',
          type: 'V2'
        },
        {
          color: 'background-color: #19397B;',
          varName: '专业版',
          enName: 'Professional Edition',
          contentone: '轻松运营销量节节攀升',
          contenttwo: '晋级跨境电商职业选手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '年/8800人民币',
          price2: '月/880人民币',
          butLabel: '购买套餐',
          type: 'V3'
        },
        {
          color: 'background-color: #121A43;',
          varName: '旗舰定制版',
          enName: 'Fagship',
          contentone: 'Vllshop给买家提供旗舰定',
          contenttwo: '制版独立站服务',
          contentthree: '提供零到一一站式服务',
          contentfour: '独立站销售培训',
          contentfive: '运营经验分享',
          contentsix: '专属咨询顾问一对一服务',
          price: '年/89999人民币',
          price2: '0',
          butLabel: '购买套餐',
          type: 'V4'
        }
      ],
      list: [
        {
          color: 'background-color: #EE9CA7;',
          comboId: 1,
          varName: '爱心版',
          enName: 'Love version',
          contentone: 'Vllshop响应国家号召',
          contenttwo: '针对应届大学生、',
          contentthree: '退伍军人、残疾人',
          contentfour: '等特殊群体的',
          contentfive: '就业和创业',
          contentsix: '提供爱心版免费服务',
          price: '0',
          price2: '0',
          butLabel: '申请套餐',
          type: 'V1'
        },
        {
          color: 'background-color: #4B6CB7;',
          comboId: 2,
          varName: '基础版',
          enName: 'Basic Edition',
          contentone: '绝佳的启动方式',
          contenttwo: '适合新手卖家快速上手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '4300元/年',
          price2: '430元/月',
          butLabel: '购买套餐',
          type: 'V2'
        },
        {
          color: 'background-color: #19397B;',
          comboId: 3,
          varName: '专业版',
          enName: 'Professional Edition',
          contentone: '轻松运营销量节节攀升',
          contenttwo: '晋级跨境电商职业选手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '8800元/年',
          price2: '880元/月',
          butLabel: '购买套餐',
          type: 'V3'
        },
        {
          color: 'background-color: #121A43;',
          comboId: 4,
          varName: '旗舰定制版',
          enName: 'Fagship',
          contentone: 'Vllshop给买家提供旗舰定',
          contenttwo: '制版独立站服务',
          contentthree: '提供零到一一站式服务',
          contentfour: '独立站销售培训',
          contentfive: '运营经验分享',
          contentsix: '专属咨询顾问一对一服务',
          price: '89999元/年',
          price2: '0',
          butLabel: '购买套餐',
          type: 'V4'
        }
      ],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: 'RatioOne',
          align: 'center',
          width: '50%',
          dataIndex: 'one',
          scopedSlots: { customRender: 'one' }
        }
      ],
      bigList1: {
        one: [
          {
            key: '1',
            name: '抽佣比例',
            textSub: '',
            one: '2%'
          },
          {
            key: '2',
            name: 'Payments 信用卡交易费率',
            textSub: '',
            one: '3.6%'
          }
        ],
        two: [
          { key: '1',
            name: '独立站基础功能',
            textSub: '运营独立站需要的所有基础功能',
            one: '✔' },
          { key: '2',
            name: `员工账号`,
            textSub: '可设置店铺后台分级管理权限',
            one: '10' },
          {
            key: '3',
            name: '多域名管理',
            textSub: '一个站点支持绑定多个域名',
            one: '✔'
          }, {
            key: '4',
            name: '多店铺管理',
            textSub: '一号多店，多店铺集中管理更省心',
            one: '✔'
          }
        ],
        // // 一件代发
        three: [
          {
            key: '1',
            name: 'V5选品',
            textSub: '一件采集商品信息，支持速卖通一件代发',
            one: '✔'
          }

        ],
        // 云手机
        threethree: [
          {
            key: '1',
            name: '云手机账户',
            textSub: '一键注册TikTok账号',
            one: ''
          },
          {
            key: '2',
            name: '赠送',
            textSub: '两台VllCloud云手机（3个月使用期）',
            one: ''
          },
          {
            key: '3',
            name: '服务',
            textSub: 'TikTok矩阵布局服务',
            one: ''
          }
        ],

        // 广告投放
        four: [
          {
            key: '1',
            name: 'V5投放',
            textSub: '智能落地页，爆款/受众标签自动推荐，提升ROAS',
            one: '✔'
          }, {
            key: '2',
            name: 'Facebook CAPI上报（支持多个）',
            textSub: '全新的Facebook数据上报解决方案',
            one: '✔'
          },
          {
            key: '3',
            name: '实时视图',
            textSub: '实时店铺数据分析，了解业务走势',
            one: '✔'
          }
        ],
        // 站内转化
        five: [
          {
            key: '1',
            name: '基础营销功能（优惠码/自动优惠/营销工具）',
            textSub: '满足商家站内基础营销需求',
            one: '✔'
          },
          {
            key: '2',
            name: '高阶营销功能（限时促销、捆绑销售）',
            textSub: '满足更丰富的营销场景需求',
            one: '' },
          {
            key: '3',
            name: '评论工具',
            textSub: '支持快速搬迁评论',
            one: ''
          },
          {
            key: '4',
            name: '邮件服务',
            textSub: '通过邮件实现用户激活和召回',
            one: ''
          }

        ],
        // 特色功能
        six: [
          { key: '1',
            name: '多货币自动切换',
            textSub: '可根据IP自动切换成消费者当地的币种',
            one: '' },
          { key: '2',
            name: '多种语言自动翻译',
            textSub: '根据浏览器语言自动切换成消费者本地语言',
            one: '' },
          { key: '3',
            name: 'V5创意',
            textSub: '创意模版素材拼接一气呵成',
            one: '' },
          { key: '4',
            name: '多结账流程配置',
            textSub: '灵活匹配不同业务场景，优化结算流程',
            one: '' }
        ],
        // 贴心服务
        seven: [
          { key: '1',
            name: '店铺前端代码微调服务',
            textSub: '轻松实现店铺样式个性化定制',
            one: '' },
          { key: '2',
            name: 'Store Launch服务',
            textSub: '专业服务团队协助建站',
            one: '' },
          { key: '3',
            name: 'Success training 服务',
            textSub: '一对一店铺运营指导',
            one: '' }
        ]
      },
      bigList2: {
        one: [
          {
            key: '1',
            name: '抽佣比例',
            textSub: '',
            one: '0.8%'
          },
          {
            key: '2',
            name: 'Payments 信用卡交易费率',
            textSub: '',
            one: '3.6%'
          }
        ],
        two: [
          { key: '1',
            name: '独立站基础功能',
            textSub: '运营独立站需要的所有基础功能',
            one: '✔' },
          { key: '2',
            name: `员工账号`,
            textSub: '可设置店铺后台分级管理权限',
            one: '10' },
          {
            key: '3',
            name: '多域名管理',
            textSub: '一个站点支持绑定多个域名',
            one: '✔'
          }, {
            key: '4',
            name: '多店铺管理',
            textSub: '一号多店，多店铺集中管理更省心',
            one: '✔'
          }
        ],
        // // 一件代发
        three: [
          {
            key: '1',
            name: 'V5选品',
            textSub: '一件采集商品信息，支持速卖通一件代发',
            one: '✔'
          }

        ],
        // 云手机
        threethree: [
          {
            key: '1',
            name: '云手机账户',
            textSub: '一键注册TikTok账号',
            one: ''
          },
          {
            key: '2',
            name: '赠送',
            textSub: '两台VllCloud云手机（3个月使用期）',
            one: ''
          },
          {
            key: '3',
            name: '服务',
            textSub: 'TikTok矩阵布局服务',
            one: ''
          }
        ],
        // 广告投放
        four: [
          {
            key: '1',
            name: 'V5投放',
            textSub: '智能落地页，爆款/受众标签自动推荐，提升ROAS',
            one: '✔'
          }, {
            key: '2',
            name: 'Facebook CAPI上报（支持多个）',
            textSub: '全新的Facebook数据上报解决方案',
            one: '✔'
          },
          {
            key: '3',
            name: '实时视图',
            textSub: '实时店铺数据分析，了解业务走势',
            one: '✔'
          }
        ],
        // 站内转化
        five: [
          {
            key: '1',
            name: '基础营销功能（优惠码/自动优惠/营销工具）',
            textSub: '满足商家站内基础营销需求',
            one: '✔'
          },
          {
            key: '2',
            name: '高阶营销功能（限时促销、捆绑销售）',
            textSub: '满足更丰富的营销场景需求',
            one: '✔' },
          {
            key: '3',
            name: '评论工具',
            textSub: '支持快速搬迁评论',
            one: '✔'
          },
          {
            key: '4',
            name: '邮件服务',
            textSub: '通过邮件实现用户激活和召回',
            one: '✔'
          }

        ],
        // 特色功能
        six: [
          { key: '1',
            name: '多货币自动切换',
            textSub: '可根据IP自动切换成消费者当地的币种',
            one: '✔' },
          { key: '2',
            name: '多种语言自动翻译',
            textSub: '根据浏览器语言自动切换成消费者本地语言',
            one: '✔' },
          { key: '3',
            name: 'V5创意',
            textSub: '创意模版素材拼接一气呵成',
            one: '✔' },
          { key: '4',
            name: '多结账流程配置',
            textSub: '灵活匹配不同业务场景，优化结算流程',
            one: '' }
        ],
        // 贴心服务
        seven: [
          { key: '1',
            name: '店铺前端代码微调服务',
            textSub: '轻松实现店铺样式个性化定制',
            one: '' },
          { key: '2',
            name: 'Store Launch服务',
            textSub: '专业服务团队协助建站',
            one: '' },
          { key: '3',
            name: 'Success training 服务',
            textSub: '一对一店铺运营指导',
            one: '' }
        ]
      },
      bigList3: {
        one: [
          {
            key: '1',
            name: '抽佣比例',
            textSub: '',
            one: '0.4%'
          },
          {
            key: '2',
            name: 'Payments 信用卡交易费率',
            textSub: '',
            one: '3.3%'
          }
        ],
        two: [
          { key: '1',
            name: '独立站基础功能',
            textSub: '运营独立站需要的所有基础功能',
            one: '✔' },
          { key: '2',
            name: `员工账号`,
            textSub: '可设置店铺后台分级管理权限',
            one: '20' },
          {
            key: '3',
            name: '多域名管理',
            textSub: '一个站点支持绑定多个域名',
            one: '✔'
          }, {
            key: '4',
            name: '多店铺管理',
            textSub: '一号多店，多店铺集中管理更省心',
            one: '✔'
          }
        ],
        // // 一件代发
        three: [
          {
            key: '1',
            name: 'V5选品',
            textSub: '一件采集商品信息，支持速卖通一件代发',
            one: '✔'
          }

        ],
        // 云手机
        threethree: [
          {
            key: '1',
            name: '云手机账户',
            textSub: '一键注册TikTok账号',
            one: ''
          },
          {
            key: '2',
            name: '赠送',
            textSub: '两台VllCloud云手机（3个月使用期）',
            one: ''
          },
          {
            key: '3',
            name: '服务',
            textSub: 'TikTok矩阵布局服务',
            one: ''
          }
        ],
        // 广告投放
        four: [
          {
            key: '1',
            name: 'V5投放',
            textSub: '智能落地页，爆款/受众标签自动推荐，提升ROAS',
            one: '✔'
          }, {
            key: '2',
            name: 'Facebook CAPI上报（支持多个）',
            textSub: '全新的Facebook数据上报解决方案',
            one: '✔'
          },
          {
            key: '3',
            name: '实时视图',
            textSub: '实时店铺数据分析，了解业务走势',
            one: '✔'
          }
        ],
        // 站内转化
        five: [
          {
            key: '1',
            name: '基础营销功能（优惠码/自动优惠/营销工具）',
            textSub: '满足商家站内基础营销需求',
            one: '✔'
          },
          {
            key: '2',
            name: '高阶营销功能（限时促销、捆绑销售）',
            textSub: '满足更丰富的营销场景需求',
            one: '✔' },
          {
            key: '3',
            name: '评论工具',
            textSub: '支持快速搬迁评论',
            one: '✔'
          },
          {
            key: '4',
            name: '邮件服务',
            textSub: '通过邮件实现用户激活和召回',
            one: '✔'
          }

        ],
        // 特色功能
        six: [
          { key: '1',
            name: '多货币自动切换',
            textSub: '可根据IP自动切换成消费者当地的币种',
            one: '✔' },
          { key: '2',
            name: '多种语言自动翻译',
            textSub: '根据浏览器语言自动切换成消费者本地语言',
            one: '✔' },
          { key: '3',
            name: 'V5创意',
            textSub: '创意模版素材拼接一气呵成',
            one: '✔' },
          { key: '4',
            name: '多结账流程配置',
            textSub: '灵活匹配不同业务场景，优化结算流程',
            one: '✔' }
        ],
        // 贴心服务
        seven: [
          { key: '1',
            name: '店铺前端代码微调服务',
            textSub: '轻松实现店铺样式个性化定制',
            one: '' },
          { key: '2',
            name: 'Store Launch服务',
            textSub: '专业服务团队协助建站',
            one: '' },
          { key: '3',
            name: 'Success training 服务',
            textSub: '一对一店铺运营指导',
            one: '' }
        ]
      },

      bigList4: {
        one: [
          {
            key: '1',
            name: '抽佣比例',
            textSub: '',
            one: '0.2%'
          },
          {
            key: '2',
            name: 'Payments 信用卡交易费率',
            textSub: '',
            one: '3.0%'
          }
        ],
        two: [
          { key: '1',
            name: '独立站基础功能',
            textSub: '运营独立站需要的所有基础功能',
            one: '✔' },
          { key: '2',
            name: `员工账号`,
            textSub: '可设置店铺后台分级管理权限',
            one: '100' },
          {
            key: '3',
            name: '多域名管理',
            textSub: '一个站点支持绑定多个域名',
            one: '✔'
          }, {
            key: '4',
            name: '多店铺管理',
            textSub: '一号多店，多店铺集中管理更省心',
            one: '✔'
          }
        ],
        // // 一件代发
        three: [
          {
            key: '1',
            name: 'V5选品',
            textSub: '一件采集商品信息，支持速卖通一件代发',
            one: '✔'
          }

        ],
        // 云手机
        threethree: [
          {
            key: '1',
            name: '云手机账户',
            textSub: '一键注册TikTok账号',
            one: '✔'
          },
          {
            key: '2',
            name: '赠送',
            textSub: '两台VllCloud云手机（3个月使用期）',
            one: '✔'
          },
          {
            key: '3',
            name: '服务',
            textSub: 'TikTok矩阵布局服务',
            one: '✔'
          }
        ],
        // 广告投放
        four: [
          {
            key: '1',
            name: 'V5投放',
            textSub: '智能落地页，爆款/受众标签自动推荐，提升ROAS',
            one: '✔'
          }, {
            key: '2',
            name: 'Facebook CAPI上报（支持多个）',
            textSub: '全新的Facebook数据上报解决方案',
            one: '✔'
          },
          {
            key: '3',
            name: '实时视图',
            textSub: '实时店铺数据分析，了解业务走势',
            one: '✔'
          }
        ],
        // 站内转化
        five: [
          {
            key: '1',
            name: '基础营销功能（优惠码/自动优惠/营销工具）',
            textSub: '满足商家站内基础营销需求',
            one: '✔'
          },
          {
            key: '2',
            name: '高阶营销功能（限时促销、捆绑销售）',
            textSub: '满足更丰富的营销场景需求',
            one: '✔' },
          {
            key: '3',
            name: '评论工具',
            textSub: '支持快速搬迁评论',
            one: '✔'
          },
          {
            key: '4',
            name: '邮件服务',
            textSub: '通过邮件实现用户激活和召回',
            one: '✔'
          }

        ],
        // 特色功能
        six: [
          { key: '1',
            name: '多货币自动切换',
            textSub: '可根据IP自动切换成消费者当地的币种',
            one: '✔' },
          { key: '2',
            name: '多种语言自动翻译',
            textSub: '根据浏览器语言自动切换成消费者本地语言',
            one: '✔' },
          { key: '3',
            name: 'V5创意',
            textSub: '创意模版素材拼接一气呵成',
            one: '✔' },
          { key: '4',
            name: '多结账流程配置',
            textSub: '灵活匹配不同业务场景，优化结算流程',
            one: '✔' }
        ],
        // 贴心服务
        seven: [
          { key: '1',
            name: '店铺前端代码微调服务',
            textSub: '轻松实现店铺样式个性化定制',
            one: '✔' },
          { key: '2',
            name: 'Store Launch服务',
            textSub: '专业服务团队协助建站',
            one: '✔' },
          { key: '3',
            name: 'Success training 服务',
            textSub: '一对一店铺运营指导',
            one: '✔' }
        ]
      }
    }
  },
  mounted() {
    this.showitem = this.list[0] // 卡片
    this.showDataList = this.bigList1
    // 获取套餐价格
    this.getPriceList()

    if (!this.showPriceBtn) {
      return
    }
    // 套餐不可降级
    getUserInfo().then((res) => {
      if (res.code === 0) {
        const userInfo = res.data
        this.comboId = Number(userInfo.comboId)
        if (this.comboId === 5) {
          this.comboId = -1
        }
      }
    })
  },
  methods: {
    // 获取套餐列表
    getPriceList() {
      getPriceList().then((res) => {
        if (res.code === 0) {
          const priceList = res.data.records
          this.list.map((item) => {
            priceList.map((resItem) => {
              if (item.type === resItem.code) {
                item.price = resItem.yearPrice === 0 ? resItem.yearPrice : `${resItem.yearPrice}元/年` // 年
                item.price2 = resItem.monthPrice === 0 ? resItem.monthPrice : `${resItem.monthPrice}元/月` // 年
                // 旗舰版额外处理
                if (item.type === 'V4') {
                  item.price2 = '0' // 旗舰版没有月付
                }
              }
            })
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleClick(tab, event) {
      this.showitem = this.list[Number(this.activeName)]
      if (Number(this.activeName) === 0) {
        this.showDataList = this.bigList1
      }
      if (Number(this.activeName) === 1) {
        this.showDataList = this.bigList2
      }
      if (Number(this.activeName) === 2) {
        this.showDataList = this.bigList3
      }
      if (Number(this.activeName) === 3) {
        this.showDataList = this.bigList4
      }
    },
    toPayPageI() {
      const index = Number(this.activeName)
      if (index === 0) {
        this.$router.push({ name: 'loveVersion' })
        return
      }
      const obj = {}
      obj.name = this.list[index].varName
      obj.priceMonth = this.list[index].price2.replace(/[^\d.]/g, '')
      obj.nameYear = this.list[index].price.replace(/[^\d.]/g, '')
      obj.type = this.list[index].type
      setShopPackage(obj)
      this.$router.push({ name: 'OrderPay' })
    // console.log(JSON.parse(getShopPackage()).name, 'ccc')
    }
  }

}
</script>

<style lang="scss" scoped>
// 购买按钮
.buybtn{
  width: 160px;
  color: #356dff;
  background-color: #fff !important;
  border: none;
}
.nobuybtn{
  width: 160px;
  color: #fff;
  background-color: transparent !important;
  border: none;
}
.contentImport{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.tipText {
  padding-top: 8px;
  padding-bottom: 20px;
  color: #7a8499;
  font-size: 12px;
  font-family: PingFang SC;
  background-color: #f4f4f4;
}
.tabs-class{
    background-color: #ffffff;
}
.card{
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    // justify-content: center;
    align-items: center;
    border: unset;
}
.card-content{
    width: 85%;
    color: #fff;
    padding-top: 15px;
    }
.card-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.table-col{
    text-align: left;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // background-color: rgb(202, 202, 202);
}
// 无数据
.table-no-data {
  display: inline-flex;
  width: 10px;
  height: 1px;
  background: #6e737c;
  opacity: 0.8;
}
//
::v-deep .el-tabs__header{
    margin:unset;
}
::v-deep .el-tabs__item{
    padding-top: 8px;
    height: 50px;
}
::v-deep .el-tabs__nav-wrap{
    margin-bottom: unset;
}
// 上浮标
::v-deep .el-tabs__active-bar{
    bottom:unset;
    height: 6px;
}
::v-deep .el-tabs__nav-wrap::after {
    content: unset
}
::v-deep .el-tabs__nav-wrap::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
    background-color: #E4E7ED;
    z-index: 1;
}
</style>
