
const colSpanHeader = 5
const colSpan = 0
export const columns = [
  {
    // title: 'Name',
    width: 319,
    dataIndex: 'name',
    className: 'customTitleClass',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' }
  },
  {
    // title: 'RatioOne',
    align: 'center',
    width: 235,
    dataIndex: 'one',
    className: 'customTitleClass',
    slots: { title: 'customTitleOne' },
    scopedSlots: { customRender: 'one' }
  },
  {
    // title: 'RatioTwo',
    align: 'center',
    width: 236,
    dataIndex: 'two',
    className: 'customTitleClass',
    slots: { title: 'customTitleTwo' },
    scopedSlots: { customRender: 'two' }
  },
  {
    // title: 'RatioThree',
    align: 'center',
    width: 236,
    dataIndex: 'three',
    className: 'customTitleClass',
    slots: { title: 'customTitleThree' },
    scopedSlots: { customRender: 'three' }
  },
  {
    // title: 'RatioFive',
    align: 'center',
    // width: 237,
    dataIndex: 'four',
    className: 'customTitleClass',
    slots: { title: 'customTitleFour' },
    scopedSlots: { customRender: 'four' }
  }
]
export const columns1 = [
  {
    // title: '店铺管理', //以自定义为准
    width: 319,
    colSpan: colSpanHeader,
    dataIndex: 'name',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' }
  },
  {
    title: 'RatioOne',
    align: 'center',
    width: 235,
    colSpan: colSpan,
    className: 'column-money',
    dataIndex: 'one',
    scopedSlots: { customRender: 'one' }
  },
  {
    title: 'RatioTwo',
    align: 'center',
    width: 236,
    colSpan: colSpan,
    dataIndex: 'two',
    scopedSlots: { customRender: 'two' }
  },
  {
    title: 'RatioThree',
    align: 'center',
    width: 236,
    colSpan: colSpan,
    dataIndex: 'three',
    scopedSlots: { customRender: 'three' }
  },
  {
    title: 'RatioFive',
    align: 'center',
    dataIndex: 'four',
    // width: 237,
    colSpan: colSpan,
    scopedSlots: { customRender: 'four' }
  }
]

// 表格数据

export const data = [
  {
    key: '1',
    name: '抽佣比例',
    textSub: '',
    one: '2%',
    two: '0.8%',
    three: '0.4%',
    four: '0.2%'
  },
  {
    key: '2',
    name: 'Payments 信用卡交易费率',
    textSub: '',
    one: '3.6%',
    two: '3.6%',
    three: '3.3%',
    four: '3.0%'
  }
]
// 店铺管理
export const data1 = [
  {
    key: '1',
    name: '独立站基础功能',
    textSub: '运营独立站需要的所有基础功能',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '2',
    name: `员工账号`,
    textSub: '可设置店铺后台分级管理权限',
    one: '10',
    two: '10',
    three: '20',
    four: '100'
  },
  {
    key: '3',
    name: '多域名管理',
    textSub: '一个站点支持绑定多个域名',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '4',
    name: '多店铺管理',
    textSub: '一号多店，多店铺集中管理更省心',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  }
]
// 一件代发
export const data2 = [
  {
    key: '1',
    name: 'V5选品',
    textSub: '一件采集商品信息，支持速卖通一件代发',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  }
]

// 云手机
export const data33 = [
  {
    key: '1',
    name: '云手机账户',
    textSub: '一键注册TikTok账号',
    one: '',
    two: '',
    three: '',
    four: '✔'
  },
  {
    key: '2',
    name: '赠送',
    textSub: '两台VllCloud云手机（3个月使用期）',
    one: '',
    two: '',
    three: '',
    four: '✔'
  },
  {
    key: '3',
    name: '服务',
    textSub: 'TikTok矩阵布局服务',
    one: '',
    two: '',
    three: '',
    four: '✔'
  }
]

// 广告投放
export const data3 = [
  {
    key: '1',
    name: 'V5投放',
    textSub: '智能落地页，爆款/受众标签自动推荐，提升ROAS',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '2',
    name: 'Facebook CAPI上报（支持多个）',
    textSub: '全新的Facebook数据上报解决方案',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '3',
    name: '实时视图',
    textSub: '实时店铺数据分析，了解业务走势',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  }
]
// 站内转化
export const data4 = [
  {
    key: '1',
    name: '基础营销功能（优惠码/自动优惠/营销工具）',
    textSub: '满足商家站内基础营销需求',
    one: '✔',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '2',
    name: '高阶营销功能（限时促销、捆绑销售）',
    textSub: '满足更丰富的营销场景需求',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '3',
    name: '评论工具',
    textSub: '支持快速搬迁评论',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '4',
    name: '邮件服务',
    textSub: '通过邮件实现用户激活和召回',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  }
]
// 特色功能
export const data5 = [
  {
    key: '1',
    name: '多货币自动切换',
    textSub: '可根据IP自动切换成消费者当地的币种',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '2',
    name: '多种语言自动翻译',
    textSub: '根据浏览器语言自动切换成消费者本地语言',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '3',
    name: 'V5创意',
    textSub: '创意模版素材拼接一气呵成',
    one: '',
    two: '✔',
    three: '✔',
    four: '✔'
  },
  {
    key: '4',
    name: '多结账流程配置',
    textSub: '灵活匹配不同业务场景，优化结算流程',
    one: '',
    two: '',
    three: '✔',
    four: '✔'
  }
]
// 贴心服务
export const data6 = [
  {
    key: '1',
    name: '店铺前端代码微调服务',
    textSub: '轻松实现店铺样式个性化定制',
    one: '',
    two: '',
    three: '',
    four: '✔'
  },
  {
    key: '2',
    name: 'Store Launch服务',
    textSub: '专业服务团队协助建站',
    one: '',
    two: '',
    three: '',
    four: '✔'
  },
  {
    key: '3',
    name: 'Success training 服务',
    textSub: '一对一店铺运营指导',
    one: '',
    two: '',
    three: '',
    four: '✔'
  }
]
