<template>
  <p v-if="text" :class="text == '✔' ? 'table-true-color' : ''">
    {{ text }}
  </p>
  <div v-else class="table-no-data" />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>
.table-no-data {
  display: inline-flex;
  width: 10px;
  height: 1px;
  background: #6e737c;
  opacity: 0.8;
}
.table-true-color {
  color: #356dff;
}
</style>
